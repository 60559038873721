<template>
  <div class="video-box">
    <div class="top">
      <div class="video">
        <template v-if="checkUrl(currentVideoUrl, 'wss')">
          <a-button v-if="videoAlarm.device.modelType === 'TD-5422E1-VT1'"  type="link" @click="changeHot(1, '.live.flv')">切换{{ currentChannel === 1 ? '热成像' : '视频' }}</a-button>
          <JessibucaAlarm  :video-url="currentVideoUrl"></JessibucaAlarm>
        </template>

        <jcWebrtcAlarmByBoli v-if="checkUrl(currentVideoUrl, 'webrtc')" :video-url="currentVideoUrl" :operation-type="operationType"></jcWebrtcAlarmByBoli>
<!--        <JcWebrtcAlarm v-if="checkUrl(currentVideoUrl, 'jc')" :video-url="currentVideoUrl" :operation-type="operationType"></JcWebrtcAlarm>-->
      </div>
      <div class="info">
                  <a-card class="alarm-list" style="width:300px;">
                    <div>设备名称: {{videoAlarm.device.deviceName}}</div>
                    <div>告警时间: {{videoAlarm.alarm.alarmTime}}</div>
                    <div>告警单位: {{videoAlarm.company.comName}}</div>
                    <div>告警类型: {{videoAlarm.alarm.alarmTypeText}}</div>
                    <div v-if="videoAlarm.alarm.alarmTypeText === '温度告警'">告警值:{{videoAlarm.alarm.alarmVal}}</div>
                    <div v-if="videoAlarm.device.deviceType == 2">告警值:{{videoAlarm.alarm.alarmVal}}</div>

<!--                    <div>设备名称: {{alarmList[0].deviceName}}</div>-->
<!--                    <div>告警时间: {{alarmList[0].alarmTime}}</div>-->
<!--                    <div>告警类型: {{alarmList[0].alarmType}}</div>-->
<!--                    <img :src="alarmList[0].pic">-->
                    <div style="margin-top: 5px" v-if="videoAlarm.device.deviceType == 1">

                      <template v-for="(pic, i) in videoAlarm.alarm.pictureUrl.split(',')" >
                        <img :src="pic" v-if="pic" :preview="i" :key="i">
                        <a-empty style="width:260px; height: 180px;background-color: #787a85; color: #fff" description="暂无图片" v-else :key="i"/>
                      </template>
<!--                      <img :src="videoAlarm.alarm.pictureUrl" v-if="videoAlarm.alarm.pictureUrl" :preview="videoAlarm.alarm.id" >-->
<!--                      <a-empty style="width:260px; height: 180px;background-color: #787a85; color: #fff" description="暂无图片" v-else />-->

                    </div>
                    <template v-if=" JSON.stringify(alarmJob) === '{}'">
                      <a-popconfirm
                          title="是否执行一键灭火功能"
                          ok-text="执行"
                          cancel-text="取消"
                          @confirm="clickFireExtinguishing(videoAlarm.alarm)"
                      >
                        <a-button type="danger" style="width: 100%; margin-top: 8px">
                          一键灭火
                        </a-button>
                      </a-popconfirm>

                    </template>
                    <template v-else>
                      <a-card>
                        <div>告警任务</div>
                        <div>创建时间:{{ alarmJob.createTime }}</div>
                        <template v-if="alarmJob.jobStatus === 1">
                          <div style="display: flex; justify-content: space-between; margin-top: 8px; width: 100%">
                            <a-popconfirm
                                title="是否解除告警"
                                ok-text="确认解除"
                                cancel-text="取消"
                                @confirm="removeAlarmJob(videoAlarm.job)"
                            >
                              <a-button type="primary" style="width: 120px" >
                                解除告警
                              </a-button>
                            </a-popconfirm>
                            <a-popconfirm
                                title="是否启动一键灭火功能"
                                ok-text="执行"
                                cancel-text="取消"
                                @confirm="autoSendOrderByAlarmJob(videoAlarm.job)"
                            >
                              <a-button type="danger" style="width: 120px" >
                                确认告警
                              </a-button>
                            </a-popconfirm>

                          </div>
                        </template>
                        <template v-if="alarmJob.jobStatus == 3">
<!--                          <div>任务执行人:{{alarmJob.actionUser_dictText || 暂无}}</div>-->
                          <div>任务执行时间:{{alarmJob.jobActionTime}}</div>
                        </template>
                        <template v-if="alarmJob.jobStatus == 0">
<!--                          <div>任务解除人:{{alarmJob.removeUser_dictText}}</div>-->
                          <div>任务解除时间:{{alarmJob.removeTime}}</div>

                        </template>
                        <template v-if="alarmJob.jobStatus == 2">
<!--                          <div>任务执行人:{{alarmJob.actionUser_dictText}}</div>-->
                          <div>任务执行时间:{{alarmJob.jobActionTime}}</div>
                        </template>
                      </a-card>
                    </template>

                  </a-card>
      </div>
<!--      <div style="font-size: 16px; font-weight: bold">设备名称：xxx摄像头告警</div>-->


<!--      <div class="monitor-alarm">-->
<!--        <div v-for="item in alarmList" :key="item">-->
<!--          <a-card class="alarm-list">-->
<!--            <div>告警时间: {{item.alarmTime}}</div>-->
<!--            <div>告警类型: {{item.alarmType}}</div>-->
<!--            <img :src="item.pic">-->
<!--          </a-card>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="device">
      <div class="monitors-list">
        <a-tag :color="activeNum === i ? 'green' : ''" v-for="(item, i) in videoAlarm.monitors" :key="i" @click="monitorClick(item, i)">
          {{ item.deviceName}}
        </a-tag>
      </div>
      <div class="device-control">
        <div>摄像头控制设备</div>
        <a-card class="item" v-for="(item,index) in orderDevice" :key="index">
          <div  >设备名称: {{item.deviceName}}</div>
          <!--            <div style=" margin-top: 5px">设备状态: {{item.status}}</div>-->
          <div style="margin-top: 5px">设备指令: </div>
          <div style="display: flex; margin-top: 8px">
            <template v-if="item.option.length === 0">
              <div style="visibility: hidden">
                <a-tag color="green" >
                  hello
                </a-tag>
              </div>
            </template>
            <template v-else>
              <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">

                <template  v-if="item.needPassword === 1">
                  <a-popconfirm
                      title="下发指令是一个危险的操作"
                      ok-text="下发"
                      cancel-text="取消"
                      @confirm="sendOrder(item, it)"
                      @cancel="cancel"

                  >
                    <a-tag color="green" >
                      {{ it.name }}
                    </a-tag>
                  </a-popconfirm>
                </template>
                <template v-else>
                  <a-tag color="green" @click="sendOrder(item, it)">
                    {{ it.name }}
                  </a-tag>
                </template>
              </div>
            </template>

          </div>
        </a-card>
      </div>




      <div class="content1">
        <div>摄像头联动设备指令下发</div>
        <a-card class="item" v-for="(item,index) in linkDeviceList" :key="index">
          <div  >设备名称: {{item.deviceName}}</div>
          <!--            <div style=" margin-top: 5px">设备状态: {{item.status}}</div>-->
          <div style="margin-top: 5px">设备指令: </div>
          <div style="display: flex; margin-top: 8px">
            <template v-if="item.option.length === 0">
              <div style="visibility: hidden">
                <a-tag color="green" >
                  hello
                </a-tag>
              </div>
            </template>
            <template v-else>
              <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">

                <template  v-if="item.needPassword === 1">
                  <a-popconfirm
                      title="下发指令是一个危险的操作"
                      ok-text="下发"
                      cancel-text="取消"
                      @confirm="sendOrder(item, it)"
                      @cancel="cancel"

                  >
                    <a-tag color="green" >
                      {{ it.name }}
                    </a-tag>
                  </a-popconfirm>
                </template>
                <template v-else>
                  <a-tag color="green" @click="sendOrder(item, it)">
                    {{ it.name }}
                  </a-tag>
                </template>
              </div>
            </template>
          </div>
        </a-card>
        <div>物联设备指令下发</div>
        <a-card class="item" v-for="(item,index) in deviceList" :key="index">
          <div  >设备名称: {{item.deviceName}}</div>
          <!--            <div style=" margin-top: 5px">设备状态: {{item.status}}</div>-->
          <div style="margin-top: 5px">设备指令: </div>
          <div style="display: flex; margin-top: 8px">
            <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">

              <template  v-if="item.needPassword === 1">
                <a-popconfirm
                    title="下发指令是一个危险的操作"
                    ok-text="下发"
                    cancel-text="取消"
                    @confirm="sendOrder(item, it)"


                >
                  <a-tag color="green" >
                    {{ it.name }}
                  </a-tag>
                </a-popconfirm>
              </template>
              <template v-else>
                <a-tag color="green" @click="sendOrder(item, it)">
                  {{ it.name }}
                </a-tag>
              </template>


            </div>
          </div>
        </a-card>
      </div>
    </div>

  </div>
</template>
<script>
import JessibucaAlarm from "@/views/alarm/JessibucaAlarm.vue";
import jcWebrtcAlarmByBoli from "@/views/alarm/JcWebrtcAlarmByBoli.vue";
// import JcWebrtcAlarm from "@/views/alarm/JcWebrtcAlarm.vue";
import {axiosServer} from "@/utils/request";
import {sendOrder} from "@/utils/commenRequset";

export default {
  name: "JcAlarmDialog",
  components:{
    JessibucaAlarm,
    jcWebrtcAlarmByBoli,
    // JcWebrtcAlarm
  },
  props: [
      "videoAlarm",
      "videoAlarmId",
      "operationType"
  ],
  mounted() {

  },
  data(){
    return{
      activeNum: 0,
      deviceList: [],
      linkDeviceList: [],
      orderDevice: [],
      currentVideoUrl: "",
      currentUrl: null,
      currentChannel: 1, //1普通2热成像
      alarmJob: {}
    }
  },
  methods: {
    sendOrder,
    initRelationDevice(deviceId){
      this.orderDevice = [];
      axiosServer({
        url: "/jc/jcGeneral/relation/device/one",
        params: {
          deviceId: deviceId
        },
        type:"POST",
      }).then(res => {
        console.log("initRelationDevice：",res);
        if (res.success){
          this.orderDevice = res.result;
        }

      })
    },
    checkUrl(url, typeStr){
      console.log("check", url)
      if (url){
        return url.includes(typeStr);
      }else {
        return false;
      }
    },
    initDevice1(comId){

      axiosServer({
        url: "/jc/jcGeneral/relation/device1",
        params: {
          comId: comId || 0,
        },
        type:"POST",
      }).then(res => {
        console.log("initDevice1：",res);
        this.deviceList = res.result;
      })
    },
    initLinkDevice1(deviceId){

      axiosServer({
        url: "/jc/jcGeneral/relation/link/device/one",
        params: {
          deviceId: deviceId
        },
        type:"POST",
      }).then(res => {
        console.log("initDevice1：",res);
        this.linkDeviceList = res.result;
      })
    },
    monitorClick(record, i){
      this.currentVideoUrl = record.wssUrl;
      this.activeNum = i;
      // this.initRelationDevice(record.id);
      this.initLinkDevice1(record.id);
    },
    clickFireExtinguishing(record){
      // console.log("clickFireExtinguishing",record)
      axiosServer({
        url: "/jc/jcGeneral/fire/extinguishing",
        type: "POST",
        params: {
          deviceId: record.deviceId
        }
      }).then(res =>{
        if (res.success){
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      }).catch(()=>{
        this.$message.error("下发错误")
      })
    },

    changeHot(type, mediaType){
      // wss://video.jckjcn.com:443/rtp/10059254044060200001_34020000001310000002.live.flv
      // this.currentUrl =
      let nums = this.currentVideoUrl.match(/\d+/g);
      let hotChannel = nums[2] === "34020000001340000001" ? "34020000001310000002":"34020000001340000001";
      if (nums[2] === "34020000001340000001"){
        this.currentChannel = 2;
      }else {
        this.currentChannel = 1;
      }
      if (type === 1){
        let oldChannelStr= nums[1] +"_" + nums[2]  + mediaType;
        let newChannelStr = nums[1] + "_" + hotChannel + mediaType;
        this.currentVideoUrl = this.currentVideoUrl.replace(oldChannelStr, newChannelStr);
      }



    },
    initAlarmJob(id){
      axiosServer({
        url: "/jc/jcAlarmJob/queryById",
        type: "GET",
        params: {
          id: id
        }
      }).then(res =>{
        if (res.success){
          this.$message.success(res.message)
          this.alarmJob = res.result;
        }else {
          this.$message.error(res.message)
        }
      })
    },
    autoSendOrderByAlarmJob(record){
      axiosServer({
        url: "/jc/jcGeneral/auto/alarmJob",
        type: "POST",
        params: {
          deviceId: record.deviceId,
          jobId: record.id
        }
      }).then(res =>{
        if (res.success){
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      }).catch(()=>{
        this.$message.error("下发错误")
      }).finally(() => {
        // this.initAlarmJobs();
        this.initAlarmJob(record.id);
      })
    },
    removeAlarmJob(record){
      axiosServer({
        url: "/jc/jcGeneral/remove/alarmJob",
        type: "POST",
        params: {
          deviceId: record.deviceId,
          jobId: record.id
        }
      }).then(res =>{
        if (res.success){
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      }).catch(()=>{
        this.$message.error("下发错误")
      }).finally(() => {
        this.initAlarmJob(record.id);
        this.$emit("refreshAlarmJob")
      })
    }
  },


}
</script>



<style scoped lang="scss">
.video-box{
  padding: 10px;
  height: 735px;

  .top{
    width: 1000px;
    height:  400px;
    display: flex;
    .video{
      width: 660px;
      margin-right: 15px;
    }
    .info{
      //display: flex;
      //max-width: 135px;
      //flex-wrap: wrap;
          img{
            width: 280px;
            height: 150px;
          }
          ::v-deep .ant-card-body{
            padding: 8px;
          }
          ::v-deep .ant-btn{
            padding: 0;
          }
    }
  }
  .device{
    padding-top: 5px;
    ::v-deep .ant-card-body{
      padding: 0;
    }
    .monitors-list{
      margin: 5px 0;
      overflow: scroll;
      display: flex;
      padding-bottom: 5px;
    }

    /* 横向滚动条样式 */
    .monitors-list::-webkit-scrollbar {
      width: 5px; /* 设置滚动条宽度 */
      height: 8px;
    }
    .monitors-list::-webkit-scrollbar-thumb {
      background-color: #888; /* 设置滑动块颜色 */
      border-radius: 5px; /* 设置滑动块圆角 */
    }
    .monitors-list::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* 设置滑道颜色 */
    }
    .monitors-list::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* 设置鼠标悬停时滑动块颜色 */
    }
    .monitors-list::-webkit-scrollbar-thumb:active {
      background-color: #000; /* 设置滑动块按下时颜色 */
    }
        //background-color: #f5222d;
    .content1{

          //display: flex;
          height: 160px;
          overflow: hidden;
          overflow-y: scroll;
        }
        .content1::-webkit-scrollbar {
          width: 0px;

        }
        .content1::-webkit-scrollbar-thumb {
          border-radius: 0;
          background: rgba(0,0,0,0.2);
        }
        .content1::-webkit-scrollbar-track {
          border-radius: 0;
          background: rgba(0,0,0,0.1);
        }
        .item {
          margin-top: 5px;
          margin-right: 5px;
          padding: 5px;
          display: inline-block;
          background-color: rgb(13, 27, 88);
          color: #fff;
          //width: 100px;
          height: 95px;
        }
  }
  //.video{
  //  width: 650px;
  //  height:  400px;
  //  //background-color: #ff5722;
  //  margin-right: 15px;
  //  .device{
  //    padding-top: 5px;
  //
  //    //background-color: #f5222d;
  //    .content1{
  //      //display: flex;
  //      height: 250px;
  //      overflow: hidden;
  //      overflow-y: scroll;
  //    }
  //    .content1::-webkit-scrollbar {
  //      width: 0px;
  //
  //    }
  //    .content1::-webkit-scrollbar-thumb {
  //      border-radius: 0;
  //      background: rgba(0,0,0,0.2);
  //    }
  //    .content1::-webkit-scrollbar-track {
  //      border-radius: 0;
  //      background: rgba(0,0,0,0.1);
  //    }
  //    .item {
  //      margin-top: 5px;
  //      margin-right: 5px;
  //      display: inline-block;
  //      background-color: rgb(13, 27, 88);
  //      color: #fff;
  //      //width: 100px;
  //      height: 120px;
  //    }
  //  }
  //}
  //.alarm{
  //  width: 300px;
  //  padding: 5px;
  //  //height:  650px;
  //
  //  .alarm-list{
  //    background-color: transparent;
  //
  //    ::v-deep .ant-card-body{
  //      padding: 8px;
  //    }
  //    ::v-deep .ant-btn{
  //      padding: 0;
  //    }
  //  }
  //
  //}
  //.monitor-alarm{
  //  width: 310px;
  //  padding: 5px;
  //  .alarm-list{
  //    background-color: transparent;
  //    img{
  //      width: 280px;
  //      height: 200px;
  //    }
  //    ::v-deep .ant-card-body{
  //      padding: 8px;
  //    }
  //    ::v-deep .ant-btn{
  //      padding: 0;
  //    }
  //  }
  //}
}

</style>