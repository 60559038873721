<template>
  <div class="webrtc-box">
    <div v-if="timerLineVisible" class="timer-line">

      <div>
        <div>回放开始时间:</div>
        <input ref="dateInput" type="date" id="dateInput" value="" style="width:200px; height:35px; margin-right: 5px">
        <a-time-picker @change="onChange"  :default-value="moment('00:00:00', 'HH:mm:ss')"   format="HH:mm:ss"  :allowClear="false"   v-model="currTime"  />
        <a-button type="link" @click="playLookBack">播放</a-button>

<!--        <a-button type="link" @click="showTime">回显时间</a-button>-->
        <div id="wrap" >
          <div id="timeSlider" ref="timeSlider">

          </div>
        </div>
      </div>


    </div>
    <div style="display: flex; justify-content: space-between">
      <div>
        <a-button type="link" @click="play">播放</a-button>
        <a-button type="link" @click="lookBack">回看</a-button>
        <a-button type="link" @click="startRecord" v-show="!recordStatus">录制</a-button>
        <a-button type="link" @click="stopRecord" v-show="recordStatus">停止录制</a-button>
      </div>
      <div v-if="statusShow">摄像头状态：{{ monitorStatus === 1 ? "在线" : "离线" }}</div>
    </div>
    <div style="display: flex; justify-content: center">
      <video ref="video"  width="900" height="480" controls autoplay> </video>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import {axiosServer} from "@/utils/request";
export default {
  name: "JcWebrtcByBoli",
  props: ['videoUrl', 'statusShow'],
  data(){
    return{
      sdk: null,
      timerLineVisible: false,
      config: {
        username: 'admin',
        passwd: '12345678',
        channelid: "1", // 0主码流 1子码流,
        nettype: 'ipv4',
        // retry: 0,
        url: null
      },
      avaiableDateList: [],
      currDate: null,
      currTime: moment('00:00:00', 'HH:mm:ss'),
      markTimes: [ "000000-000000-C"],
      timeSliderInstance: null,
      timelineShow: false,
      zhCN,
      fp: null,
      monitorStatus: 0,
      recordStatus: 0,
      recordBuffer: [],
      mediaRecorder: null,

    }
  },
  mounted() {
    // this.play();
    // this.$refs.video.addEventListener('loadstart', function() {
    //   console.log('提示视频的元数据已加载')
    // })
    // this.$refs.video.addEventListener('canplay', function() {
    //   console.log('canplay')
    // })
    // this.$refs.video.addEventListener('error', function (){
    //   console.log('An error occurred:', event);
    // })
    // this.$refs.video.addEventListener('loadedmetadata', function (){
    //   console.log('loadedmetadata:', event);
    // })

    // this.requestFileDays()
  },
  methods:{
    moment,
    formatDate(dateObj){
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // 月份从0开始
      const day = String(dateObj.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    getDate(){
      return new Date();
    },
    initFlatpickr(){

      let that = this;
      const today = new Date().toISOString().split('T')[0];
      console.log("today", today);
      let date = new Date();
      this.currDate = today;
      // eslint-disable-next-line no-undef
      this.fp = flatpickr("#dateInput",{
        dateFormat: "Y-m-d",
        locale: 'zh',
        defaultDate: date,
        onDayCreate: function(dObj, dStr, fp, dayElem) {
          const date = that.formatDate(dayElem.dateObj);

          // 标记特定日期
          if (that.avaiableDateList.includes(date)) {
            dayElem.classList.add('marked-date');
          }

          // 标记今天的日期
          if (date === today) {
            dayElem.classList.add('today-date');
          }
        },
        onChange: function(selectedDates, dateStr, instance) {
          console.log('Date selected:', dateStr, instance);
          // if (dateStr) {
          //   setBackgroundColor('lightgreen'); // 设置日期选择后的背景色
          // } else {
          //   setBackgroundColor('white'); // 重置为默认背景色
          // }
          that.currDate = dateStr;

          that.requestTimeinfo(dateStr);
        },
        onOpen: function(selectedDates, dateStr) {
          console.log('Date selected:', dateStr, selectedDates);
          console.log('dateStr selected:', dateStr);
          // console.log('Calendar opened');
          // var dateVal= $('#dateInput').val();
          that.requestFileDays(dateStr);
        },
        onMonthChange: function(selectedDates, dateStr, instance) {
          console.log('Date selected:',selectedDates);
          console.log('dateStr selected:', dateStr,instance.currentMonth+1);
          let dateVal= String(instance.currentYear%2000).padStart(2, '0') + "-" + String(instance.currentMonth+1).padStart(2, '0') + "-01";
          // console.log("Month changed to:", instance.currentMonth + 1);
          // var dateVal= String(instance.currentYear%2000).padStart(2, '0') + "-" + String(instance.currentMonth+1).padStart(2, '0') + "-01";
          that.requestFileDays(dateVal);
        }
      })
    },
    initDeviceStatus(url){
      axiosServer({
        url: "/jc/jcGeneral/push/boli/webrtc/camera",
        type: "POST",
        params: {
          sendUrl: url
        }
      }).then(res => {
        console.log("pushUrl:", res)
        if (res.success){
          this.monitorStatus = res.result ? 1 : 0;

        }

      }).finally(()=>{

      })
    },
    stopCamera(url) {
       axiosServer({
         url: "/jc/jcGeneral/stop/boli/webrtc/camera",
         type: "POST",
         params: {
           sendUrl: url
         }
       })
     },
    showTime(){
      this.currTime = moment("12:11:15", "HH:mm:ss");
    },
    closeVideo(){
      if (this.sdk){
        this.sdk.close();
      }

    },
    getCurrentStyle(current){
      // const style = {};
      // let curDate = current.year() + "-" +String(current.month()).padStart(2, "0") + "-" + String(current.date()).padStart(2, "0");
      //
      //
      // if (this.avaiableDateList.includes(curDate)){
      //     style.backgroundColor = '#03f517';
      //   console.log(curDate, this.avaiableDateList)
      //   return style;
      // }
      const style = {};
      this.avaiableDateList.map(item => {
        // 假设我们要特殊渲染的日期
        const specialDate = moment(item);
        if (current.isSame(specialDate, 'day')) {
          console.log(specialDate, item)
          style.backgroundColor = '#03f517';
          return style;
        }
      })



    },
    onOk(value){
      console.log('onOk: ', value);
    },
    onChange(time, timeString){
      console.log("onChange", time)
      console.log("onChange", timeString)
      this.currTime = moment(timeString, "HH:mm:ss");
    },
    onChangeByDate(date, dateString){
      // console.log("onChangeByDate", e)
      console.log('openChangeByDate ', date)
      console.log('openChangeByDate ', dateString)
      this.currDate = dateString;
      this.requestFileDays(dateString)
      this.requestTimeinfo(dateString);
    },
    openChange(e){

      console.log("openChange", e)
    },
    openChangeByDate(e){
      console.log("openChange", e)
      if (e){
        // this.timelineShow = false;

      }

      // this.requestTimeinfo(e);
    },
    // disabledDate(current){
    //   let date = moment(current).format("YYYY-MM-DD");
    //   return this.avaiableDateList.indexOf(date) === -1;
    //
    // },
    play(){
      console.log("webrtcplay")
      // sdk 存在先销毁
      if (this.sdk) {
        this.sdk.close();
        this.stopRecordNoDownload();
      }
      // eslint-disable-next-line no-undef
      this.sdk = new WRtcPlayerAsync();

      console.log("sdk",this.sdk);
      console.log("startPlay url:", this.videoUrl);
      this.$refs.video.srcObject = this.sdk.stream;
      this.config.url = this.videoUrl;
      this.config.httpType = 1;
      this.config.channelid = "1";

      this.config.apiUrl =  this.getConfigUrl(this.videoUrl);

      this.sdk.play(this.config).then(res => {
        console.log("sdk play", res)
      }).catch(e => {
        console.log("webrtc异常:" ,e)
        this.sdk.close();
      })
    },
    lookBack(){
      this.fp = null;
      this.timerLineVisible = !this.timerLineVisible;
      if (this.timerLineVisible){
        this.$nextTick(()=>{
          this.updateTimeline();
        })

        // this.timelineShow = true;

        // let date = new Date().getFullYear() +"-" + String((new Date().getMonth()+1)).padStart(2, "0") +"-"  +String( new Date().getDay()).padStart(2, "0");
        let date = moment().format("YYYY-MM-DD");
        this.requestFileDays(date);
        this.requestTimeinfo(date);
        this.currDate = date;

      }else {
        this.currTime = null;
        this.currDate = null;

      }
    },
    playLookBack(){
      if (!this.currDate){
        this.$message.warn("请选择日期");
        return;
      }
      if (!this.currTime){
        this.$message.warn("请选择时间");
        return;
      }
      if (this.sdk) {
        this.sdk.close();
        this.stopRecordNoDownload();
      }
      console.log("this.currDate", this.currDate)
      // eslint-disable-next-line no-undef
      this.sdk = new WRtcPlayerAsync();
      console.log("sdk",this.sdk);
      console.log("startPlay url:", this.videoUrl);
      this.$refs.video.srcObject = this.sdk.stream;
      this.config.channelid = "3";
      this.config.url = this.videoUrl;
      this.config.starttime = this.currDate + "-" + this.currTime._i;
      this.config.apiUrl = this.getConfigUrl(this.videoUrl);
      this.sdk.play(this.config).then(res => {
        console.log("sdk play", res)
      }).catch(e => {
        console.log("webrtc异常:" ,e)
        this.sdk.close();
      })
    },
    requestFileDays(dateString){

      let schema = 'https:';
      let defaultPath = '/api/v1/device/getfiledays';
      let port = '1990';
      let ip = this.getIp(this.videoUrl);
      let apiUrl = schema + '//' + ip + ':' + port + defaultPath;
      let streamIdStr = this.videoUrl.split("/");
      let streamId = streamIdStr[streamIdStr.length - 1];


      let data = {
        type: 1,
        namespaceId: "TESTWRTC",
        namespaceName: "TESTWRTC",
        deviceId: streamId,
        name: "device1",
        desc: "test wrtc",
        username: "admin",
        password: "123456",
        location: {
          enable: true,
          type: 1,
          longitude: 0,
          latitude: 0
        },
        date: dateString
      };
      this.getFileDays(apiUrl, data);

    },
    async getFileDays(apiUrl, config){
      let that = this;
      await new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          if (xhr.readyState !== xhr.DONE) return;
          if (xhr.status !== 200) return reject(xhr);
          const data = JSON.parse(xhr.responseText);
          console.log("Got answer: ", data);
          if (data.count > 0){
            console.log("filedays: ", data.filedays);
            that.avaiableDateList = data.filedays;

            if (!that.fp){
              console.log("initFlatpickr")
              that.initFlatpickr();
            }else {
              console.log("redraw")
              that.fp.redraw();
            }
            // markedDates.length = 0;
            // markedDates = data.filedays.slice();
            // console.log("markedDates: ", markedDates);
            // updateMarkedDate();
          }
          return data.code ? reject(xhr) : resolve(data);
        }
        xhr.open('POST', apiUrl, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.send(JSON.stringify(config));
      });
    },
    requestTimeinfo(dateString){
      let schema = 'https:';
      let defaultPath = '/api/v1/device/gettimeinfo';
      let port = '1990';
      let ip = 'wrtc.dvr168.org';
      if (this.videoUrl.includes("dxrtc.jckjcn.com")){
        ip = "dxrtc.jckjcn.com";
      }
      if (this.videoUrl.includes("jcydrtc.jckjcn.com")){
        ip = "jcydrtc.jckjcn.com";
      }
      let apiUrl = schema + '//' + ip + ':' + port + defaultPath;
      let streamIdStr = this.videoUrl.split("/");
      let streamId = streamIdStr[streamIdStr.length - 1];


      let data = {
        type: 1,
        namespaceId: "TESTWRTC",
        namespaceName: "TESTWRTC",
        deviceId: streamId,
        name: "device1",
        desc: "test wrtc",
        username: "admin",
        password: "123456",
        location: {
          enable: true,
          type: 1,
          longitude: 0,
          latitude: 0
        },
        date: dateString
      };
      this.getTimeinfo(apiUrl, data);
    },
    async getTimeinfo(apiUrl, config){
      let that = this;
       await new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          if (xhr.readyState !== xhr.DONE) return;
          if (xhr.status !== 200) return reject(xhr);
          const data = JSON.parse(xhr.responseText);
          console.log("Got answer: ", data);
          if (data.code === 0){
            that.markTimes = [
              "000000-000000-C"];
          }
          if (data.count > 0){
            that.markTimes = data.timeinfo;

            // console.log("timeinfo: ", data.timeinfo);
            // iframeTimeline.contentWindow.postMessage(data, '*');
          }else {
            that.markTimes = [
              "000000-000000-C"];
          }

          that.$nextTick(()=>{

            that.updateTimeline();
          })
          return data.code ? reject(xhr) : resolve(data);
        }
        xhr.open('POST', apiUrl, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.send(JSON.stringify(config));
      });
    },
    updateTimeline(){


      if (this.timeSliderInstance) {
        let element = this.$refs.timeSlider;
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
        this.timeSliderInstance.timeLineStop();
        //
        // this.timelineShow = false;
        // this.timeSliderInstance = null;

        // document.body.innerHTML = '';
        // const wrapDiv = document.createElement('div');
        // wrapDiv.id = 'wrap';
        // const timeSliderDiv = document.createElement('div');
        // timeSliderDiv.id = 'timeSlider';
        // wrapDiv.appendChild(timeSliderDiv);
        // document.body.appendChild(wrapDiv);
      }
      // this.timelineShow = true;
      let that = this;
      let _moment = moment;
      // eslint-disable-next-line no-undef
      this.timeSliderInstance = new initTimeSlider('#timeSlider', {
        curDaytimeChunkArray: this.markTimes,
        presentSeconds: 0,
        timeChunkType: {
          A: 'red',            //alarm
          B: 'yellow',         //
          C: 'green'            //normal
        },
        speed: 0,
        isInitialPlay: false,
        onClick(oldSeconds, newSeconds) {
          console.log(newSeconds, oldSeconds, Number.isFinite(newSeconds))
          if (Number.isFinite(newSeconds)){
            let hour = Math.floor(newSeconds / 3600);
            let min = Math.floor((newSeconds % 3600) / 60);
            let sec = Math.floor((newSeconds % 3600) % 60);


            that.currTime = _moment(String(hour).padStart(2, "0") + ":" + String(min).padStart(2, "0") + ":"+ String(sec).padStart(2, "0"), "HH:mm:ss");
            console.log(hour, min, sec, that.currTime)

          }else {
            that.currTime = _moment(String(0).padStart(2, "0") + ":" + String(0).padStart(2, "0") + ":"+ String(0).padStart(2, "0"), "HH:mm:ss");


          }
          that.timeSliderInstance.timeLineStop();
        },
        onMove() {
          // console.log('onMove')
        },
        onMouseDown() {
          // console.log('onMousedown')
        }
      });
      this.timeSliderInstance.timeLineStop();

    },
    startRecord(){
      this.recordStatus = 1;
      this.recordBuffer = [];
      this.mediaRecorder = null;

      let options = {
        mimeType: 'video/webm;codecs=h264'
      };

      if(!MediaRecorder.isTypeSupported(options.mimeType)){
        this.$message.error("您的浏览器不支持录制回放功能")
        return;
      }
      try{
        this.mediaRecorder = new MediaRecorder(this.sdk.stream, options)
      }catch (e) {
        console.log('Filed tp create MideaRecoder',e);
        return;
      }

      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.start(10);
    },
    handleDataAvailable(e) {
      if(e&& e.data && e.data.size > 0){
        this.recordBuffer.push(e.data);
      }
    },
    stopRecord(){
      this.recordStatus = 0;
      this.mediaRecorder.stop();
      this.downloadVideo();
    },
    stopRecordNoDownload(){
      if (this.mediaRecorder){
        this.mediaRecorder.stop();
      }
      this.mediaRecorder = null;

      this.recordStatus = 0;
      this.recordBuffer = [];
    },
    downloadVideo(){
      let blob = new Blob(this.recordBuffer,{type: 'video/webm'});
      console.log(blob);
      let url = window.URL.createObjectURL(blob);

      let filename =  "回放" + new Date().getTime() + ".webm";
      console.log(filename);
      let a = document.createElement('a');
      a.href = url;
      a.style.display = 'none';
      a.download = filename;
      a.click();
    },
    getIp(url){
      if (url.includes("dxrtc.jckjcn.com")){
        return  "dxrtc.jckjcn.com";
      }
      if (url.includes("jcydrtc.jckjcn.com")){
        return "jcydrtc.jckjcn.com";
      }
      return "wrtc.dvr168.org";
    },
    getConfigUrl(url){
      if (url.includes("dxrtc.jckjcn.com")){
        return  "https://dxrtc.jckjcn.com:1990/rtc/v1/play/";
      }
      if (url.includes("jcydrtc.jckjcn.com")){
        return "https://jcydrtc.jckjcn.com:1990/rtc/v1/play/";
      }
      return "https://wrtc.dvr168.org:1990/rtc/v1/play/";
    }
  },
  watch: {
    videoUrl: {
      handler(newVal, oldVal) {
        console.log("webrtcwatch")

        if (newVal !== oldVal){
          this.$nextTick(()=>{
            this.timerLineVisible = false;
            this.play();
            this.initDeviceStatus(newVal);
            this.stopCamera(oldVal);
            this.stopRecordNoDownload();
          })
        }

      },
      immediate: true
    }
  },
  destroyed() {
    console.log("webrtc销毁")
    this.sdk.close();
    this.stopCamera(this.videoUrl);

  }
}
</script>



<style scoped lang="scss">
.webrtc-box{
  position: relative;
}
.timer-line{
  width: 800px;
  height: 150px;
  border: 1px solid #f5222d;
  background-color: #0f1325;
  position: absolute;
  top: -150px;
  left: 0;
}
video{
  object-fit: fill;
}
::v-deep .ant-btn{
  padding: 0 8px;
}
</style>